import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Label } from "theme-ui";

import { Divider, Icon, Modal } from "ui";
import Button from "@coninsa-s2/button";
import { getNormalizerServiceType } from "modules/search-building/utils";
import BuildingContactForm from "./BuildingContactForm";
import { sendZohoInfo } from "../api";

function PropertyInfo({
  canon,
  code,
  path,
  propertyType,
  region,
  serviceType,
  className = "",
}) {
  const [open, setOpen] = useState(false);
  const showForm = () => setOpen(true);

  const [state, setState] = useState(false);
  const handler = () => setState(true);
  const closeHandler = () => {
    setState(false);
  };

  const [submitted, setSubmitted] = useState(false);

  const whatsappPath = `https://api.whatsapp.com/send?phone=573014726883&text=Hola! Los vi en coninsa.co, me gustaría obtener más información. Inmueble: ${code}`;

  const pdfPath = `https://busquedas.coninsa.co/generar-ficha-inmuebles/${code}`;

  return (
    <div className={`${className}`}>
      {submitted && (
        <Box sx={{ px: 5, textAlign: "center" }}>
          <Icon
            name="fa-reg-check-circle"
            sx={{ color: "secondary", fontSize: "47px" }}
          />
          <Label
            sx={{
              display: "block",
              textAlign: "center",
              color: "secondary",
              fontWeight: "semibold",
            }}
          >
            ¡Hemos recibido tus datos!
          </Label>
          <Box sx={{ fontSize: 1 }}>
            Pronto un asesor te llamará para brindarte toda la información sobre
            este inmueble.
          </Box>
        </Box>
      )}

      {!submitted && (
        <div className="sticky top-10 rounded-lg bg-white p-8 shadow-[0px_3px_6px_#00000029]">
          <div className="mb-4 font-bold">¿Quieres arrendar este inmueble?</div>

          <div className="flex flex-col gap-4">
            {/* <Button href="/estudio-digital" target="_blank">
              <Icon name="fa-reg-file" />
              Iniciar mi estudio digital
            </Button> */}

            <Button inverted onClick={() => setOpen(!open)}>
              <Icon name="fa-calendar" />
              Agendar una cita
            </Button>

            {open && (
              <div>
                <Divider className="mb-4" />

                <BuildingContactForm
                  code={code}
                  canon={canon}
                  path={path}
                  propertyType={propertyType}
                  region={region}
                  serviceType={serviceType}
                  onSubmitForm={sendZohoInfo}
                  redirectType="THANKS_PAGE"
                />
              </div>
            )}
          </div>

          <Divider my="24px" />
          <div>
            <div className="mb-4 font-bold">Recibe más información</div>

            <Button size="fluid" onClick={handler} tint="green">
              <Icon name="fa-whatsapp" />
              Contactar a un asesor
            </Button>

            <Modal
              sx={{ width: ["100%", "500px"] }}
              open={state}
              onClose={closeHandler}
              variant="fullDark"
              showCloseControl={true}
            >
              <Modal.Content variant="fullDark">
                <div className="bg-s2-white p-8">
                  <BuildingContactForm
                    bg="green"
                    iconName="fa-whatsapp"
                    buttonLabel="Contactar a un asesor"
                    code={code}
                    canon={canon}
                    path={path}
                    propertyType={propertyType}
                    region={region}
                    serviceType={serviceType}
                    onSubmitForm={sendZohoInfo}
                    redirectType="WHATSAPP_PAGE"
                  />
                </div>
              </Modal.Content>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
}

PropertyInfo.defaultProps = {
  canon: "",
};

PropertyInfo.propTypes = {
  canon: PropTypes.string,
  code: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
};

export default PropertyInfo;
